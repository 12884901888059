import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { ExportJobProvider } from '../types/export-job-provider';
import { ArticleExport } from '../types/export.type';
import { ExportsFilters } from '../types/exports-filters.type';

import {
  failedFetchingExportsAction,
  fetchExportsAction,
  loadFetchedExportsAction,
  refreshExportsAction,
  setExportJobProvidersAction,
} from './exports.actions';

export const exportFeatureKey = 'content-manager-exports';

export interface ExportsState {
  status: 'loading' | 'loaded' | 'error';
  filters: ExportsFilters;
  list: EntityState<ArticleExport>;
  numberOfAllItems: number;
  providers?: readonly ExportJobProvider[]; // TODO: Bin mir nicht sicher, ob das hier rein soll, oder einen komplett eigenen State/Reducer etc.
}

export const adapter: EntityAdapter<ArticleExport> = createEntityAdapter<ArticleExport>({
  selectId: (s) => s.articleExportId,
});
export const initialState: ExportsState = {
  status: 'loading',
  filters: {
    stateType: '',
    exportTypes: [],
    searchTerm: '',
    warningsOnly: false,
    numberOfDays: 90,
    page: 1,
    pageSize: 50,
  },
  numberOfAllItems: 0,
  list: adapter.getInitialState(),
};

export const exportsReducer = createReducer(
  initialState,
  on(refreshExportsAction, (state): ExportsState => {
    return {
      ...state,
      status: 'loading',
      numberOfAllItems: 0,
      list: adapter.getInitialState(),
    };
  }),
  on(fetchExportsAction, (state, { filters }): ExportsState => {
    return {
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
      status: 'loading',
      numberOfAllItems: 0,
      list: adapter.getInitialState(),
    };
  }),
  on(loadFetchedExportsAction, (state, { list, numberOfAllItems }): ExportsState => {
    return {
      ...state,
      status: 'loaded',
      numberOfAllItems,
      list: adapter.setMany(list, adapter.getInitialState()),
    };
  }),
  on(failedFetchingExportsAction, (state): ExportsState => {
    return {
      ...state,
      status: 'error',
    };
  }),

  on(setExportJobProvidersAction, (state, { providers }): ExportsState => {
    return {
      ...state,
      providers,
    };
  }),
);
