import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FilterInfo } from '@core/ui';
import { getExportsAsideFilters } from '@mp/content-manager/exports/util';

import {
  deleteExportsItemAction,
  fetchExportJobProvidersAction,
  fetchExportsAction,
  regenerateExportsItemAction,
} from '../exports/exports.actions';
import {
  selectExportJobProviders,
  selectExportsFilters,
  selectExportsList,
  selectExportsStatus,
  selectNumberOfAllItems,
} from '../exports/exports.selectors';
import { ExportJobProvider } from '../types/export-job-provider';
import { ArticleExport } from '../types/export.type';
import { ExportsFilters } from '../types/exports-filters.type';

@Injectable({
  providedIn: 'root',
})
export class ExportFacade {
  readonly status$: Observable<'loading' | 'loaded' | 'error'> = this.store$.select(selectExportsStatus);
  readonly filters$: Observable<ExportsFilters> = this.store$.select(selectExportsFilters);
  readonly asideFiltersSummary$: Observable<FilterInfo[]> = this.filters$.pipe(
    map((filters) => getExportsAsideFilters(filters)),
  );
  readonly numberOfAllItems$: Observable<number> = this.store$.select(selectNumberOfAllItems);
  readonly list$: Observable<ArticleExport[]> = this.store$.select(selectExportsList);

  /**
   * Gibt die Liste der zur Verfügung stehenden Export-Routinen zurück.
   */
  readonly exportJobProviders$: Observable<readonly ExportJobProvider[]> = this.store$.select(selectExportJobProviders);

  readonly isLoaded$: Observable<boolean> = this.status$.pipe(map((status) => status === 'loaded'));

  constructor(private store$: Store) {}

  fetchExports(filters: Partial<ExportsFilters>): void {
    this.store$.dispatch(fetchExportsAction({ filters }));
  }

  deleteExportItem(id: string): void {
    this.store$.dispatch(deleteExportsItemAction({ id }));
  }

  regenerateExportItem(id: string): void {
    this.store$.dispatch(regenerateExportsItemAction({ id }));
  }

  /**
   * Triggert das Abrufen den zur Verfügung stehenden Export-Routinen.
   * @param initial `true`, um nicht erneut zu laden, wenn diese bereits geladen wurden.
   */
  fetchExportJobProviders(initial?: boolean): void {
    this.store$.dispatch(fetchExportJobProvidersAction({ initial }));
  }
}
