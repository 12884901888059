import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { ExportsApiService } from '../services/exports-api.service';

import {
  deleteExportsItemAction,
  failedFetchingExportsAction,
  fetchExportJobProvidersAction,
  fetchExportsAction,
  loadFetchedExportsAction,
  refreshExportsAction,
  regenerateExportsItemAction,
  setExportJobProvidersAction,
} from './exports.actions';
import { selectExportJobProvidersLoaded, selectExportsFilters } from './exports.selectors';

@Injectable()
export class ExportsEffects {
  constructor(
    private store$: Store,
    private actions$: Actions,
    private exportsApi: ExportsApiService,
  ) {}

  fetchEffects$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchExportsAction, refreshExportsAction),
      concatLatestFrom(() => this.store$.select(selectExportsFilters)),
      switchMap(([, filters]) =>
        this.exportsApi.getList(filters).pipe(
          map(({ list, numberOfAllItems }) => loadFetchedExportsAction({ list, numberOfAllItems })),
          catchError(() => of(failedFetchingExportsAction())),
        ),
      ),
    );
  });

  deleteEffects$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteExportsItemAction),
      // eslint-disable-next-line rxjs/no-unsafe-switchmap
      switchMap(({ id }) => this.exportsApi.deleteExportItem(id).pipe(map(() => refreshExportsAction()))),
    );
  });

  regenerateEffects$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(regenerateExportsItemAction),
      switchMap(({ id }) => this.exportsApi.regenerateExportItem(id).pipe(map(() => refreshExportsAction()))),
    );
  });

  fetchExportJobProvidersEffects$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchExportJobProvidersAction /* TODO: wennSichEineBerechtigungDesAktuellenNutzersÄndertAction */),
      concatLatestFrom(() => this.store$.select(selectExportJobProvidersLoaded)),
      filter(([action, providersLoaded]) => action.initial !== true || !providersLoaded),
      switchMap(() =>
        this.exportsApi.getProviders().pipe(map((providers) => setExportJobProvidersAction({ providers }))),
      ),
    );
  });
}
