import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { exportFeatureKey, exportsReducer } from './exports/exports.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ExportsEffects } from './exports/exports.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(exportFeatureKey, exportsReducer),
    EffectsModule.forFeature([ExportsEffects])
  ],
  declarations: [],
  providers: [],
})
export class ExportsDataAccessModule {}
