import { createAction, props } from '@ngrx/store';
import { ExportsFilters } from '../types/exports-filters.type';
import { ArticleExport } from '../types/export.type';
import { ExportJobProvider } from '../types/export-job-provider';

export const regenerateExportsItemAction = createAction(
  '[Exports] re-generate export item',
  props<{ id: string }>()
);

export const deleteExportsItemAction = createAction(
  '[Exports] Delete export item',
  props<{ id: string }>()
);

export const refreshExportsAction = createAction(
  '[Exports] Refresh Exports',
);

export const fetchExportsAction = createAction(
  '[Exports] Fetch Exports',
  props<{ filters: Partial<ExportsFilters> }>()
);

export const loadFetchedExportsAction = createAction(
  '[Exports] Load fetched exports',
  props<{ list: Array<ArticleExport>, numberOfAllItems: number }>()
);

export const failedFetchingExportsAction = createAction(
  '[Exports] Failed fetching exports'
);

export const fetchExportJobProvidersAction = createAction(
  '[Exports] Fetch export job providers',
  props<{ initial?: boolean }>(),
);

export const setExportJobProvidersAction = createAction(
  '[Exports] Set export job providers',
  props<{ providers: readonly ExportJobProvider[] }>(),
);
