export type ExportsFilters = {
  stateType: ArticleExportStateType | '',
  exportTypes: string[],
  searchTerm: string,
  numberOfDays: number,
  page: number,
  pageSize: number,
  warningsOnly: boolean;
}

export enum ArticleExportStateType {
  Pending = 'Pending',
  Successful = 'Successful',
  Failed = 'Failed',
}
