import { FilterInfo } from '@core/ui';
import { ExportsFilters } from '@mp/content-manager/exports/data-access';

export function getExportsAsideFilters({
  searchTerm,
  warningsOnly,
  stateType,
  exportTypes,
}: ExportsFilters): FilterInfo[] {
  return [
    {
      icon: 'search',
      title: 'Nach Export/Benutzer suchen',
      isActive: !!searchTerm,
    },
    {
      icon: 'report_problem',
      title: 'Nur mit Warnungen',
      isActive: warningsOnly,
    },
    {
      icon: 'verified',
      title: 'Status',
      isActive: !!stateType,
    },
    {
      icon: 'label',
      title: 'Typ',
      isActive: !!exportTypes && exportTypes.length > 0,
    },
  ];
}
