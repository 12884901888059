import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ExportsState, adapter, exportFeatureKey } from './exports.reducer';

export const selectExportsState = createFeatureSelector<ExportsState>(exportFeatureKey);

export const selectExportsStatus = createSelector(selectExportsState, (state: ExportsState) => state.status);

export const selectExportsFilters = createSelector(selectExportsState, (state: ExportsState) => state.filters);

export const selectExportsList = createSelector(selectExportsState, (state: ExportsState) => {
  return adapter.getSelectors().selectAll(state.list);
});

export const selectNumberOfAllItems = createSelector(
  selectExportsState,
  (state: ExportsState) => state.numberOfAllItems,
);

export const selectExportJobProviders = createSelector(selectExportsState, (state) => state.providers ?? []);

export const selectExportJobProvidersLoaded = createSelector(selectExportsState, (state) => state.providers != null);
